
import { computed, defineComponent, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ViewProfile",
  setup() {
    const router = useRouter();

    let activeTab = "#general";
    if (router.currentRoute.value.hash !== "") {
      activeTab = router.currentRoute.value.hash;
    }

    const store = useStore();

    return {
      activeTab: ref(activeTab),
      user: ref(computed(() => store.state.user)),
    };
  },
  methods: {
    setActiveTab(tabName: string): void {
      this.activeTab = tabName;
    },
    setModal(componentName: string): void {
      this.$store.dispatch("setModal", componentName);
    },
  },
});
